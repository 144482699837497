.aboutContainer h1 {
  color: var(--clr-accent);
}
.aboutContainer h1:before {
  content: '';
  height: 1px;
  min-width: 10px;
  background-color: currentColor;
}
.aboutContainer h1::after {
  content: '';
  margin-left: 1rem;
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}
.aboutContainer p {
  color: var(--clr-text-muted);
  font-size: clamp(1rem, 5vw, 1.3rem);
  max-width: 50ch;
}
.aboutContainer a {
  color: var(--clr-secondary);
  text-decoration: none;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor), linear-gradient(currentColor, currentColor);
  background-position: 0% 103%, 100% 103%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s;
}

.aboutContainer a:hover,
a:focus {
  background-size: 50% 2px;
}