.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wave {
  animation: wave infinite;
}
.wave1 {
  width: 100%;
  height: 20%;
  left: 0;
  bottom: 0;
  z-index: 4;
  position: absolute;
}
.wave2 {
  width: 100%;
  height: 30%;
  left: 0;

  bottom: 0;
  z-index: 3;
  position: absolute;
}
.wave3 {
  width: 100%;
  height: 40%;
  left: 0;

  bottom: 0;
  z-index: 2;
  position: absolute;
}
.wave4 {
  width: 100%;
  height: 50%;
  left: 0;

  bottom: 0;
  z-index: 1;
  position: absolute;
}
/* .wave5 {
  clip-path: polygon(
    0 91%,
    14% 96%,
    35% 91%,
    50% 93%,
    64% 95%,
    84% 99%,
    100% 97%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(41, 187, 187);
}
.wave4 {
  clip-path: polygon(
    0 87%,
    15% 89%,
    35% 87%,
    50% 89%,
    66% 91%,
    83% 85%,
    100% 80%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(55, 153, 153);
}
.wave3 {
  clip-path: polygon(
    0 74%,
    16% 84%,
    35% 80%,
    51% 87%,
    66% 81%,
    81% 83%,
    99% 88%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(61, 124, 124);
}
.wave2 {
  clip-path: polygon(
    0 71%,
    16% 74%,
    29% 67%,
    47% 70%,
    66% 67%,
    81% 71%,
    99% 78%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(44, 78, 78);
}
.wave1 {
  clip-path: polygon(
    0 69%,
    6% 65%,
    29% 67%,
    47% 65%,
    66% 66%,
    86% 68%,
    100% 74%,
    100% 100%,
    0 100%
  );
  position: absolute;
  background-color: rgb(22, 32, 32);
} */

@keyframes wave {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 10px);
  }
  30% {
    transform: translate(0, 15px);
  }
  50% {
    transform: translate(0, 20px);
  }
  70% {
    transform: translate(0, 15px);
  }
  90% {
    transform: translate(0, 10px);
  }
  0% {
    transform: translate(0, 0);
  }
}
