.heroSection {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}
.contentContainer {
  display: flex;
  flex-flow: row wrap-reverse;
  align-content: center;
  justify-content: center;
  gap: 5rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}
.textContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 1rem;
  text-align: right;
  align-items: flex-end;
}
.textContainer h3 {
  color: var(--clr-accent);
  font-size: clamp(1vw, 1rem, 1rem);
  line-height: clamp(1vw, 1rem, 1rem);
  margin-right: 18px;
}
.textContainer h1 {
  font-size: clamp(3rem, 7vw, 5rem);
  line-height: clamp(3rem, 7vw, 5rem);
  letter-spacing: 0.2rem;
}
.textContainer h2 {
  color: var(--clr-accent);
  filter: contrast(10%);
  font-size: clamp(2rem, 3vw, 3rem);
  line-height: clamp(2rem, 3vw, 3rem);
}
.textContainer p {
  padding-top: 0.5rem;
  max-width: 50ch;
  filter: contrast(10%);
  line-height: 1.8rem;
}
.contact {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  margin: 0 5rem;
}
.contact p {
  filter: brightness(30%);
}
.contactButton {
  padding: 1rem 2rem;
  width: 15rem;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 3px solid var(--clr-accent);
  border-image: linear-gradient(
    to right,
    var(--clr-accent),
    var(--clr-dark-accent)
  );
  border-image-slice: 1;
  color: var(--clr-accent);
  filter: contrast(80%);
  font-size: 1.4rem;
}
.contactButton::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--clr-accent);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.contactButton:hover::before {
  opacity: 0.1;
}
.socials {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
}
@media screen and (max-width: 1390px) {
  .textContainer {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
