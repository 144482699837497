.projectsContainer {
  margin-top: 10rem;
  align-self: center;
  width: 100%;
}
.projects {
  gap: 2.6rem;
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
  margin: auto;
  justify-content: center;
}

.header {
  color: var(--clr-accent);
  margin: 5rem auto 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}
.header:before {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.header:after {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.project {
  border-radius: 15px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: -3px 3px 10px 5px rgba(20, 20, 20, 0.452);
  margin-top: 2rem;
}

.preview {
  width: 100%;
  height: 100%;
  box-shadow: 4px 0px 2px 0px rgba(14, 14, 14, 0.425);
  border-radius: 5px;
  position: absolute;
  object-fit: cover;
}

.project::after {
  content: '';
  background-color: rgba(48, 48, 48, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 5px;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  width: 100%;
  padding: 2rem;
  z-index: 1;
  min-height: 35vw;
  position: relative;
}

.project h2 {
  color: var(--clr-secondary);
  font-size: clamp(1.5rem, 5vw, 2.1rem);
  font-weight: 900;
  width: 100%;
}

.content p {
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: var(--clr-secondary);
  filter: saturate(0.6);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 2rem;
}
.techContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.technologyTag {
  font-size: clamp(0.5rem, 2vw, 0.7rem);
  color: white;
  background-color: var(--clr-about-muted);
  border-radius: 10px;
  padding: 0.2rem 0.6rem;
  text-align: center;
}
.links {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  bottom: 0;
  transform: translate(-2rem, 105%);
  width: 100%;
  box-shadow: -3px 3px 8px 0px rgb(14 14 14 / 63%);
  z-index: -1;
}
.links a {
  width: 50%;
  text-align: center;
  text-decoration: none;
  padding: 0.4rem 0;
}
.links a:hover {
  filter: brightness(120%);
}
.links a:nth-child(1) {
  border-radius: 0 0 0px 5px;
  background-color: rgb(77, 77, 77);
  color: white;
}
.links a:nth-child(2) {
  background-color: var(--clr-secondary);
  border-radius: 0 0 5px 0;
  color: var(--clr-background);
}

.randomContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-self: center;
  gap: 1rem;
  margin-top: 5rem;
}
.randomContainer h3 {
  color: var(--clr-accent);
  background-color: var(--clr-background-off);
  filter: saturate(0.5);
  border-radius: 10px;
  padding: 1rem 2rem;
  box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.2);
}
.randomProjectContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1.4rem;
  width: 80%;
  background-color: var(--clr-background-off);
  padding: 2rem 2rem;
  border-radius: 10px;
  align-content: center;
  justify-content: center;
  box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.2);
}
.randomProjectContainer h2 {
  color: var(--clr-secondary);
}
.randomProjectContainer p {
  font-size: clamp(0.9rem, 1vw, 1rem);
  width: 60%;
}

.randomProjectContainer a {
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  background-color: rgb(80, 80, 80);
  border-radius: 15px;
}

.randomProjectContainer a:hover {
  filter: brightness(150%);
  cursor: pointer;
}

@media screen and (min-width: 1100px) {
  .projects {
    flex-flow: row wrap;
  }
  .project {
    box-shadow: none;
    width: 40%;
    background-color: pink;
    min-height: 400px;
  }
  .project::after {
    content: none;
  }
  .preview {
    box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.7);
    z-index: 2;
  }
  .content {
    min-height: 0;
  }
  .content p {
    position: absolute;
    bottom: 10%;
    top: 10%;
    background-color: var(--clr-background-off);
    box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.2);
    padding: 2rem;
    transition: all 0.5s ease-out;
    gap: 2rem;
    width: 30ch;
    z-index: -1;
    border-radius: 10px;
  }
  li:nth-child(odd) p {
    left: 0;
    transform: translateX(-95%);
  }
  li:nth-child(even) p {
    right: 0;
    transform: translateX(95%);
  }
  .content h2 {
    position: absolute;
    width: auto;
    border-radius: 4px;
    padding: 1rem 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 1.9vw, 2.1rem);
    transition: all 0.5s ease-out;
  }
  li:nth-child(odd) h2 {
    left: 0;
    transform: translate(-97%, -40%);
  }
  li:nth-child(even) h2 {
    right: 0;
    transform: translate(97%, -40%);
  }
  .techContainer {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    width: auto;
    max-height: 6rem;
    z-index: 10;
    margin: 1rem 1rem;
    max-width: 28ch;
    padding: 0.5rem;
  }
  li:nth-child(odd) .techContainer {
    left: 0;
    transform: translateX(-107%);
  }
  li:nth-child(even) .techContainer {
    right: 0;
    transform: translateX(107%);
  }
  .technologyTag {
    padding: 0.2rem 0.5rem;
    background-color: var(--clr-accent);
    color: var(--clr-background);
    border-radius: 15px;
    filter: saturate(0.6);
  }
}
