.contact {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}
.contact h1 {
  color: var(--clr-accent);
  margin: 5rem auto 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}
.contact h1:before {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.contact h1:after {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.contact p {
  color: var(--clr-text-muted);
  font-size: clamp(1rem, 5vw, 1.3rem);
  max-width: 30ch;
  text-align: center;
  margin: 2rem;
}

.contactButton {
  padding: 1rem 1.6rem;
  width: 15rem;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 3px solid var(--clr-secondary);
  border-image-slice: 1;
  color: var(--clr-secondary);
  filter: contrast(80%);
  font-size: 1.4rem;
}
.contactButton::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--clr-secondary);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.contactButton:hover::before {
  opacity: 0.1;
}
