@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;800;900&display=swap);
main {
  display: flex;
  flex-flow: column nowrap;
}

.App_themeButtonContainer__1SKDK {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: all 0.7s ease-out;
}

.App_loaded__3Zhp5.App_themeButtonContainer__1SKDK {
  top: 96%;
  left: 96%;
}
.App_content__3La4L {
  display: flex;
  flex-flow: column;
  width: clamp(45vw, 70vw, 60rem);
  margin: auto;
  justify-content: center;
}
.App_content__3La4L h1 {
  -webkit-filter: contrast(50%);
          filter: contrast(50%);
  letter-spacing: 0.2rem;
  font-size: clamp(1rem, 5vw, 2.5rem);
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 80%;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.App_meContainer__1m38s {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 5rem;
  gap: 5rem;
}
.App_meContainer__1m38s > * {
  width: clamp(30ch, 45vw, 50ch);
}

.App_tempFooter__rQjtd {
  height: 50px;
  padding-top: 200rem;
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 2450px) {
  .App_meContainer__1m38s {
    justify-content: center;
  }
}

nav {
  width: 100%;
}
nav ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 2rem 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}
nav ul li a {
  color: var(--clr-secondary);
  text-decoration: none;
}
nav ul li a:hover {
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
}
.Nav_resume__39mWP {
  all: unset;
  background-color: var(--clr-secondary);
  border-radius: 10px;
  padding: 1rem 1.4rem;
  color: var(--clr-background);
}
.Nav_resume__39mWP:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
  cursor: pointer;
}

.Chessboard_chessboard__35Ivr {
  width: 200px;
  height: 200px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(8, auto);
  overflow: hidden;
}
.Chessboard_square__1LwWx {
  width: 20px;
  height: 20px;
  background-color: rgba(243, 243, 243, 0.7);
  -webkit-animation: Chessboard_moveLeft__NMaDZ 0.3s ease-in-out;
          animation: Chessboard_moveLeft__NMaDZ 0.3s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.Chessboard_chessboard__35Ivr div:nth-child(-2n + 8),
.Chessboard_chessboard__35Ivr div:nth-child(8) ~ div:nth-child(-2n + 15),
.Chessboard_chessboard__35Ivr div:nth-child(16) ~ div:nth-child(-2n + 24),
.Chessboard_chessboard__35Ivr div:nth-child(24) ~ div:nth-child(-2n + 31),
.Chessboard_chessboard__35Ivr div:nth-child(32) ~ div:nth-child(-2n + 40),
.Chessboard_chessboard__35Ivr div:nth-child(40) ~ div:nth-child(-2n + 47),
.Chessboard_chessboard__35Ivr div:nth-child(48) ~ div:nth-child(-2n + 56),
.Chessboard_chessboard__35Ivr div:nth-child(56) ~ div:nth-child(-2n + 63) {
  background-color: hsl(29, 34%, 10%, 0.7);
}

@-webkit-keyframes Chessboard_moveLeft__NMaDZ {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(-400px, 0px);
            transform: translate(-400px, 0px);
  }
}

@keyframes Chessboard_moveLeft__NMaDZ {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(-400px, 0px);
            transform: translate(-400px, 0px);
  }
}

.Sudoku_sudoku__1ODsy {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, auto);
}
.Sudoku_subgrid__3mZo7 {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(3, auto);
  height: 60px;
  width: 60px;
  -webkit-animation: Sudoku_pop__1nLoT 1s linear;
          animation: Sudoku_pop__1nLoT 1s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.Sudoku_sudokuSquare__2s0de {
  height: 20px;
  width: 20px;
  background-color: rgb(243, 243, 243, 0.5);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.79);
  color: rgba(56, 56, 56, 0.529);
}

@-webkit-keyframes Sudoku_pop__1nLoT {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.2;
  }
  90% {
    opacity: .6;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Sudoku_pop__1nLoT {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.2;
  }
  90% {
    opacity: .6;
  }
  100% {
    opacity: 0;
  }
}

.Notes_bookwise__3y1rg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  -webkit-filter: contrast(60%);
          filter: contrast(60%);
}

.Notes_books___rzGN {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  width: 30%;
  margin-left: 1rem;
}
.Notes_book__3l5Gd {
  width: 90%;
  height: 20px;
  background-color: rgb(58, 58, 58);
  border-radius: 5px;
  -webkit-animation-name: Notes_fallDown__1du9x;
          animation-name: Notes_fallDown__1du9x;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: left 50%;
          transform-origin: left 50%;
}
.Notes_chapters__PcxT8 {
  width: 60%;
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}
.Notes_chapter__W6FcR {
  background-color: rgb(49, 49, 49);
  height: 20px;
  border-radius: 10px;
  -webkit-animation-name: Notes_fallDown__1du9x;
          animation-name: Notes_fallDown__1du9x;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 30% 50%;
          transform-origin: 30% 50%;
}
.Notes_note__1TztP {
  width: 70%;
  height: 20px;
  border-radius: 10px;
  -webkit-animation-name: Notes_fallDown__1du9x;
          animation-name: Notes_fallDown__1du9x;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: left 50%;
          transform-origin: left 50%;
  margin-top: 0.2rem;
}
.Notes_active__Llpkd {
  background-color: hsla(303, 67%, 34%, 0.8);
}
.Notes_pinned__1ip8Y {
  background-color: rgba(34, 145, 34, 0.8);
}

@-webkit-keyframes Notes_fallDown__1du9x {
  0% {
    -webkit-transform: translate(0, 0) rotate(0turn);
            transform: translate(0, 0) rotate(0turn);
  }
  10% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
  20% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  30% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  40% {
    -webkit-transform: rotate(70deg);
            transform: rotate(70deg);
  }
  50% {
    -webkit-transform: rotate(110deg);
            transform: rotate(110deg);
  }
  80% {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  100% {
    -webkit-transform: translate(0, 400px);
            transform: translate(0, 400px);
  }
}

@keyframes Notes_fallDown__1du9x {
  0% {
    -webkit-transform: translate(0, 0) rotate(0turn);
            transform: translate(0, 0) rotate(0turn);
  }
  10% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
  20% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  30% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  40% {
    -webkit-transform: rotate(70deg);
            transform: rotate(70deg);
  }
  50% {
    -webkit-transform: rotate(110deg);
            transform: rotate(110deg);
  }
  80% {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  100% {
    -webkit-transform: translate(0, 400px);
            transform: translate(0, 400px);
  }
}

.Waves_container__2sIzS {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Waves_wave__iXFeO {
  -webkit-animation: Waves_wave__iXFeO infinite;
          animation: Waves_wave__iXFeO infinite;
}
.Waves_wave1__13Oa9 {
  width: 100%;
  height: 20%;
  left: 0;
  bottom: 0;
  z-index: 4;
  position: absolute;
}
.Waves_wave2__3vYrA {
  width: 100%;
  height: 30%;
  left: 0;

  bottom: 0;
  z-index: 3;
  position: absolute;
}
.Waves_wave3__8NJgV {
  width: 100%;
  height: 40%;
  left: 0;

  bottom: 0;
  z-index: 2;
  position: absolute;
}
.Waves_wave4__2CP0s {
  width: 100%;
  height: 50%;
  left: 0;

  bottom: 0;
  z-index: 1;
  position: absolute;
}
/* .wave5 {
  clip-path: polygon(
    0 91%,
    14% 96%,
    35% 91%,
    50% 93%,
    64% 95%,
    84% 99%,
    100% 97%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(41, 187, 187);
}
.wave4 {
  clip-path: polygon(
    0 87%,
    15% 89%,
    35% 87%,
    50% 89%,
    66% 91%,
    83% 85%,
    100% 80%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(55, 153, 153);
}
.wave3 {
  clip-path: polygon(
    0 74%,
    16% 84%,
    35% 80%,
    51% 87%,
    66% 81%,
    81% 83%,
    99% 88%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(61, 124, 124);
}
.wave2 {
  clip-path: polygon(
    0 71%,
    16% 74%,
    29% 67%,
    47% 70%,
    66% 67%,
    81% 71%,
    99% 78%,
    100% 100%,
    0 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(44, 78, 78);
}
.wave1 {
  clip-path: polygon(
    0 69%,
    6% 65%,
    29% 67%,
    47% 65%,
    66% 66%,
    86% 68%,
    100% 74%,
    100% 100%,
    0 100%
  );
  position: absolute;
  background-color: rgb(22, 32, 32);
} */

@-webkit-keyframes Waves_wave__iXFeO {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px);
  }
  30% {
    -webkit-transform: translate(0, 15px);
            transform: translate(0, 15px);
  }
  50% {
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
  }
  70% {
    -webkit-transform: translate(0, 15px);
            transform: translate(0, 15px);
  }
  90% {
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px);
  }
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes Waves_wave__iXFeO {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px);
  }
  30% {
    -webkit-transform: translate(0, 15px);
            transform: translate(0, 15px);
  }
  50% {
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
  }
  70% {
    -webkit-transform: translate(0, 15px);
            transform: translate(0, 15px);
  }
  90% {
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px);
  }
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.MockScreen_screen__CUEA- {
  width: clamp(100px, 300px, 70vw);
  height: clamp(100px, 300px, 70vw);
  -webkit-transform: skew(-10deg, -10deg);
          transform: skew(-10deg, -10deg);
  position: relative;
  background-color: rgb(125 145 162);
  box-shadow: 12px 12px 2px 0px var(--clr-accent);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4rem;
  margin-bottom: 4rem;
}
.MockScreen_screen__CUEA- > * {
  -webkit-animation: MockScreen_fadeIn__2t_73 7s linear infinite;
          animation: MockScreen_fadeIn__2t_73 7s linear infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@media screen and (max-width: 1225px) {
  .MockScreen_screen__CUEA- {
    align-self: center;
    order: 1;
    margin-left: 0;
    margin-bottom: 0;
  }
}
@-webkit-keyframes MockScreen_fadeIn__2t_73 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes MockScreen_fadeIn__2t_73 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Socials_container__3qUGZ {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 0.6rem;
  gap: 0.6rem;
}
.Socials_icon__Gbn5q {
  max-height: 2rem;
  -webkit-filter: contrast(30%);
          filter: contrast(30%);
}
.Socials_icon__Gbn5q:hover {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.Welcome_heroSection__3i4G4 {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
}
.Welcome_contentContainer__3zXol {
  display: flex;
  flex-flow: row wrap-reverse;
  align-content: center;
  justify-content: center;
  grid-gap: 5rem;
  gap: 5rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}
.Welcome_textContainer__27Rug {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  text-align: right;
  align-items: flex-end;
}
.Welcome_textContainer__27Rug h3 {
  color: var(--clr-accent);
  font-size: clamp(1vw, 1rem, 1rem);
  line-height: clamp(1vw, 1rem, 1rem);
  margin-right: 18px;
}
.Welcome_textContainer__27Rug h1 {
  font-size: clamp(3rem, 7vw, 5rem);
  line-height: clamp(3rem, 7vw, 5rem);
  letter-spacing: 0.2rem;
}
.Welcome_textContainer__27Rug h2 {
  color: var(--clr-accent);
  -webkit-filter: contrast(10%);
          filter: contrast(10%);
  font-size: clamp(2rem, 3vw, 3rem);
  line-height: clamp(2rem, 3vw, 3rem);
}
.Welcome_textContainer__27Rug p {
  padding-top: 0.5rem;
  max-width: 50ch;
  -webkit-filter: contrast(10%);
          filter: contrast(10%);
  line-height: 1.8rem;
}
.Welcome_contact__2zknF {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
  margin: 0 5rem;
}
.Welcome_contact__2zknF p {
  -webkit-filter: brightness(30%);
          filter: brightness(30%);
}
.Welcome_contactButton__2DQlj {
  padding: 1rem 2rem;
  width: 15rem;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 3px solid var(--clr-accent);
  border-image: linear-gradient(
    to right,
    var(--clr-accent),
    var(--clr-dark-accent)
  );
  border-image-slice: 1;
  color: var(--clr-accent);
  -webkit-filter: contrast(80%);
          filter: contrast(80%);
  font-size: 1.4rem;
}
.Welcome_contactButton__2DQlj::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--clr-accent);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.Welcome_contactButton__2DQlj:hover::before {
  opacity: 0.1;
}
.Welcome_socials__g4z37 {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 1rem;
  gap: 1rem;
}
@media screen and (max-width: 1390px) {
  .Welcome_textContainer__27Rug {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.themebutton_inactive__bCc45 {
  -webkit-animation: themebutton_rotation__3_Udm 10s linear infinite;
          animation: themebutton_rotation__3_Udm 10s linear infinite;
}
.themebutton_active__fhoNd {
  cursor: pointer;
  background-color: var(--clr-background);
}
.themebutton_themeButton__1fdnx {
  position: relative;
  padding: 1rem;
  border-radius: 50%;
  aspect-ratio: 1;
  display: grid;
  -webkit-transform-origin: center;
          transform-origin: center;
}
.themebutton_themeButton__1fdnx > * {
  grid-column-start: 1;
  grid-row-start: 1;
}
.themebutton_active__fhoNd:hover {
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
  -webkit-animation: themebutton_rotation__3_Udm 10s linear infinite;
          animation: themebutton_rotation__3_Udm 10s linear infinite;
}
.themebutton_themeButton__1fdnx::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgb(255, 255, 255, 0.4);
  border-radius: inherit;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  z-index: 100;
}
.themebutton_themeButton__1fdnx::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--clr-stars);
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
  border-radius: inherit;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  z-index: 100;
}
.themebutton_themeButton__1fdnx.themebutton_dark__16hiS::after {
  -webkit-animation: themebutton_supernova__2gdC8 650ms ease-out;
          animation: themebutton_supernova__2gdC8 650ms ease-out;
}
.themebutton_themeButton__1fdnx.themebutton_light__3an9Z::before {
  -webkit-animation: themebutton_starborn__2B2R7 850ms ease-in;
          animation: themebutton_starborn__2B2R7 850ms ease-in;
}
.themebutton_sun__9ySN4 {
  fill: var(--clr-stars);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: rotate(0turn) scale(1);
          transform: rotate(0turn) scale(1);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 1s ease-out;
  transition: transform 1s ease-out;
  transition: transform 1s ease-out, -webkit-transform 1s ease-out;
  width: 100%;
}
.themebutton_sun__9ySN4.themebutton_light__3an9Z {
  z-index: 99;
}
.themebutton_sun__9ySN4.themebutton_dark__16hiS {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(3turn) scale(0.25);
          transform: rotate(3turn) scale(0.25);
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}
.themebutton_sun__9ySN4.themebutton_dark__16hiS .themebutton_circle__1uSwo {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(0.15);
          transform: scale(0.15);
  transition: -webkit-transform 1s ease-in;
  transition: transform 1s ease-in;
  transition: transform 1s ease-in, -webkit-transform 1s ease-in;
}
.themebutton_blackhole__XKykA.themebutton_dark__16hiS {
  z-index: 99;
}
.themebutton_blackhole__XKykA {
  -webkit-transform-origin: center;
          transform-origin: center;
  fill: rgb(219, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: -webkit-transform 1s ease-out;
  transition: transform 1s ease-out;
  transition: transform 1s ease-out, -webkit-transform 1s ease-out;
  -webkit-transform: rotate(0turn) scale(1);
          transform: rotate(0turn) scale(1);
  width: 100%;
  height: 100%;
}
.themebutton_blackhole__XKykA.themebutton_light__3an9Z {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(3turn) scale(0.25);
          transform: rotate(3turn) scale(0.25);
  transition: -webkit-transform 0.4s ease-in;
  transition: transform 0.4s ease-in;
  transition: transform 0.4s ease-in, -webkit-transform 0.4s ease-in;
}

.themebutton_stars__3YZqL {
  -webkit-transform-origin: center;
          transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: -webkit-transform 2s ease-out 0.2s;
  transition: transform 2s ease-out 0.2s;
  transition: transform 2s ease-out 0.2s, -webkit-transform 2s ease-out 0.2s;
  -webkit-transform: rotate(0turn) scale(1.3);
          transform: rotate(0turn) scale(1.3);
  fill: var(--clr-stars);
}
.themebutton_stars__3YZqL.themebutton_light__3an9Z {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(1turn) scale(0.05);
          transform: rotate(1turn) scale(0.05);
  transition: -webkit-transform 1s ease-in;
  transition: transform 1s ease-in;
  transition: transform 1s ease-in, -webkit-transform 1s ease-in;
}
.themebutton_circle__1uSwo {
  fill: var(--clr-background);
}

@-webkit-keyframes themebutton_rotation__3_Udm {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes themebutton_rotation__3_Udm {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@-webkit-keyframes themebutton_supernova__2gdC8 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  20% {
    -webkit-transform: scaleX(0) scaleY(0);
            transform: scaleX(0) scaleY(0);
  }
  60% {
    -webkit-transform: scaleX(1) scaleY(0.01);
            transform: scaleX(1) scaleY(0.01);
    opacity: 1;
  }
  75% {
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes themebutton_supernova__2gdC8 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  20% {
    -webkit-transform: scaleX(0) scaleY(0);
            transform: scaleX(0) scaleY(0);
  }
  60% {
    -webkit-transform: scaleX(1) scaleY(0.01);
            transform: scaleX(1) scaleY(0.01);
    opacity: 1;
  }
  75% {
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes themebutton_starborn__2B2R7 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.5;
  }
  10% {
  }
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes themebutton_starborn__2B2R7 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.5;
  }
  10% {
  }
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.About_aboutContainer__3UCzP h1 {
  color: var(--clr-accent);
}
.About_aboutContainer__3UCzP h1:before {
  content: '';
  height: 1px;
  min-width: 10px;
  background-color: currentColor;
}
.About_aboutContainer__3UCzP h1::after {
  content: '';
  margin-left: 1rem;
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}
.About_aboutContainer__3UCzP p {
  color: var(--clr-text-muted);
  font-size: clamp(1rem, 5vw, 1.3rem);
  max-width: 50ch;
}
.About_aboutContainer__3UCzP a {
  color: var(--clr-secondary);
  text-decoration: none;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor), linear-gradient(currentColor, currentColor);
  background-position: 0% 103%, 100% 103%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s;
}

.About_aboutContainer__3UCzP a:hover,
a:focus {
  background-size: 50% 2px;
}
.Technologies_techContainer__1cc32 {
  margin-left: auto;
}
.Technologies_techContainer__1cc32 h1 {
  text-align: right;
  margin-left: auto;
  color: var(--clr-accent);
}
.Technologies_techContainer__1cc32 h1:after {
  content: '';
  height: 1px;
  min-width: 10px;
  background-color: currentColor;
}
.Technologies_techContainer__1cc32 h1::before {
  content: '';
  margin-left: 1rem;
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}
.Technologies_zones__2M51t {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  grid-gap: 2rem;
  gap: 2rem;
}
.Technologies_zone__2cRap {
  flex-basis: 44%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  align-items: center;
  margin: auto;
}
.Technologies_zone__2cRap > div {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.Technologies_zone__2cRap > div::before {
  content: '';
  height: 100px;
  width: 200px;
  position: relative;
  border-radius: 29px;
}
.Technologies_zone__2cRap div img {
  max-height: 70%;
  position: absolute;
}
.Technologies_zone__2cRap h2 {
  color: var(--clr-secondary);
  -webkit-filter: contrast(40%);
          filter: contrast(40%);
  text-align: center;
  margin: 0.6rem 0rem;
  width: 13ch;
}
.Technologies_zone__2cRap p {
  color: var(--clr-text-muted);
  font-size: 1rem;
  max-width: 20ch;
  text-align: center;
}
@media screen and (max-width: 2450px) {
  .Technologies_techContainer__1cc32 {
    margin-left: 0;
  }
  .Technologies_zones__2M51t {
    justify-content: center;
  }
  .Technologies_zone__2cRap {
    justify-content: center;
  }
}

.FrontEnd_frontEnd__1xtB1::before {
  background: linear-gradient(
    142.35deg,
    #64558e 15.59%,
    rgba(145, 88, 147, 0.84) 89.12%
  );
}
.FrontEnd_frontEnd__1xtB1.FrontEnd_icon__1Jr45 .FrontEnd_static__1-Frt {
  max-height: 55%;
  box-shadow: -3px 3px 3px 1px rgba(0, 0, 0, 0.233);
  border-radius: 5px;
}
.FrontEnd_frontEnd__1xtB1.FrontEnd_icon__1Jr45 .FrontEnd_animated__36rOB {
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.FrontEnd_FEButton__3KyLH {
  -webkit-animation: FrontEnd_buttonClick__2Rn-X 8s infinite ease-in-out;
          animation: FrontEnd_buttonClick__2Rn-X 8s infinite ease-in-out;
}
.FrontEnd_FEMouse__2Kk_T {
  -webkit-animation: FrontEnd_mouseClick__1_puL 8s infinite ease-in-out;
          animation: FrontEnd_mouseClick__1_puL 8s infinite ease-in-out;
  height: 40%;
  left: 47%;
  top: 38%;
}

@-webkit-keyframes FrontEnd_mouseClick__1_puL {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes FrontEnd_mouseClick__1_puL {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes FrontEnd_buttonClick__2Rn-X {
  0% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  10% {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
  }
  20% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  100% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
}
@keyframes FrontEnd_buttonClick__2Rn-X {
  0% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  10% {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
  }
  20% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  100% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
}

.BackEnd_backEnd__2vu_o::before {
  background: linear-gradient(142.35deg, #a83f3f 15.59%, #ffd5dd 89.12%);
}
.BackEnd_backEnd__2vu_o {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.BackEnd_backEnd__2vu_o.BackEnd_icon__2Ao2F .BackEnd_static__1ohyz {
  max-height: 55%;
  border-radius: 5px;
}
.BackEnd_light__zijHX {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: BackEnd_blink__2n3Bx 10s infinite ease-in-out;
          animation: BackEnd_blink__2n3Bx 10s infinite ease-in-out;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.BackEnd_topleft__36JJy {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  top: 29%;
  left: 42.5%;
}
.BackEnd_topright__3eU0H {
  -webkit-animation-delay: 8.2s;
          animation-delay: 8.2s;
  top: 29%;
  left: 74%;
}
.BackEnd_bottomleft__2K_2h {
  -webkit-animation-delay: 13.2s;
          animation-delay: 13.2s;
  top: 58%;
  left: 42.5%;
}
.BackEnd_bottomright__IL9Hd {
  -webkit-animation-delay: 18.7s;
          animation-delay: 18.7s;
  top: 58%;
  left: 74%;
}

@-webkit-keyframes BackEnd_blink__2n3Bx {
  0% {
    background-color: rgb(62, 155, 62);
  }
  5% {
    background-color: rgb(255, 61, 61);
  }
  10% {
    background-color: rgb(62, 155, 62);
  }

  100% {
    background-color: rgb(62, 155, 62);
  }
}

@keyframes BackEnd_blink__2n3Bx {
  0% {
    background-color: rgb(62, 155, 62);
  }
  5% {
    background-color: rgb(255, 61, 61);
  }
  10% {
    background-color: rgb(62, 155, 62);
  }

  100% {
    background-color: rgb(62, 155, 62);
  }
}

.DevOps_devOps__3HCXA::before {
  background: linear-gradient(142.35deg, #bd9d4a 15.59%, #ce8e85 89.12%);
}
.DevOps_devOps__3HCXA.DevOps_icon__2NZjs .DevOps_static__3EUey {
  max-height: 55%;
  box-shadow: -3px 3px 3px 1px rgba(0, 0, 0, 0.233);
  border-radius: 5px;
}

.DevOps_devOps__3HCXA.DevOps_icon__2NZjs .DevOps_bash__3s7qA {
  font-size: 1.4em;
  position: absolute;
  top: 20%;
  left: 35%;
  color: white;
}

.DevOps_bash__3s7qA span {
  -webkit-animation: DevOps_blink__32sYi 4s infinite ease-in-out;
          animation: DevOps_blink__32sYi 4s infinite ease-in-out;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes DevOps_blink__32sYi {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes DevOps_blink__32sYi {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.Design_design__1Qrck::before {
  background: linear-gradient(142.35deg, #44728c 15.59%, #9ee6ba 89.12%);
}
.Design_static__JkFU7 {
  max-height: 55%;
  left: 47%;
  top: 15%;
}
.Design_animated__279_i {
  -webkit-animation: Design_spin__2j3Qb 4s infinite ease-in-out;
          animation: Design_spin__2j3Qb 4s infinite ease-in-out;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes Design_spin__2j3Qb {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  100% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
}

@keyframes Design_spin__2j3Qb {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  100% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
}

.Projects_projectsContainer__3WSMK {
  margin-top: 10rem;
  align-self: center;
  width: 100%;
}
.Projects_projects__1Wgds {
  grid-gap: 2.6rem;
  gap: 2.6rem;
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
  margin: auto;
  justify-content: center;
}

.Projects_header__13R-L {
  color: var(--clr-accent);
  margin: 5rem auto 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}
.Projects_header__13R-L:before {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.Projects_header__13R-L:after {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.Projects_project__22eSF {
  border-radius: 15px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: -3px 3px 10px 5px rgba(20, 20, 20, 0.452);
  margin-top: 2rem;
}

.Projects_preview___jleB {
  width: 100%;
  height: 100%;
  box-shadow: 4px 0px 2px 0px rgba(14, 14, 14, 0.425);
  border-radius: 5px;
  position: absolute;
  object-fit: cover;
}

.Projects_project__22eSF::after {
  content: '';
  background-color: rgba(48, 48, 48, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 5px;
}

.Projects_content__VddC0 {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
  padding: 2rem;
  z-index: 1;
  min-height: 35vw;
  position: relative;
}

.Projects_project__22eSF h2 {
  color: var(--clr-secondary);
  font-size: clamp(1.5rem, 5vw, 2.1rem);
  font-weight: 900;
  width: 100%;
}

.Projects_content__VddC0 p {
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: var(--clr-secondary);
  -webkit-filter: saturate(0.6);
          filter: saturate(0.6);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}
.Projects_techContainer__Ciyem {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.Projects_technologyTag__2EIqk {
  font-size: clamp(0.5rem, 2vw, 0.7rem);
  color: white;
  background-color: var(--clr-about-muted);
  border-radius: 10px;
  padding: 0.2rem 0.6rem;
  text-align: center;
}
.Projects_links__1L2-2 {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  bottom: 0;
  -webkit-transform: translate(-2rem, 105%);
          transform: translate(-2rem, 105%);
  width: 100%;
  box-shadow: -3px 3px 8px 0px rgb(14 14 14 / 63%);
  z-index: -1;
}
.Projects_links__1L2-2 a {
  width: 50%;
  text-align: center;
  text-decoration: none;
  padding: 0.4rem 0;
}
.Projects_links__1L2-2 a:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}
.Projects_links__1L2-2 a:nth-child(1) {
  border-radius: 0 0 0px 5px;
  background-color: rgb(77, 77, 77);
  color: white;
}
.Projects_links__1L2-2 a:nth-child(2) {
  background-color: var(--clr-secondary);
  border-radius: 0 0 5px 0;
  color: var(--clr-background);
}

.Projects_randomContainer__b0b9v {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-self: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 5rem;
}
.Projects_randomContainer__b0b9v h3 {
  color: var(--clr-accent);
  background-color: var(--clr-background-off);
  -webkit-filter: saturate(0.5);
          filter: saturate(0.5);
  border-radius: 10px;
  padding: 1rem 2rem;
  box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.2);
}
.Projects_randomProjectContainer__168ZK {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  grid-gap: 1.4rem;
  gap: 1.4rem;
  width: 80%;
  background-color: var(--clr-background-off);
  padding: 2rem 2rem;
  border-radius: 10px;
  align-content: center;
  justify-content: center;
  box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.2);
}
.Projects_randomProjectContainer__168ZK h2 {
  color: var(--clr-secondary);
}
.Projects_randomProjectContainer__168ZK p {
  font-size: clamp(0.9rem, 1vw, 1rem);
  width: 60%;
}

.Projects_randomProjectContainer__168ZK a {
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  background-color: rgb(80, 80, 80);
  border-radius: 15px;
}

.Projects_randomProjectContainer__168ZK a:hover {
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
  cursor: pointer;
}

@media screen and (min-width: 1100px) {
  .Projects_projects__1Wgds {
    flex-flow: row wrap;
  }
  .Projects_project__22eSF {
    box-shadow: none;
    width: 40%;
    background-color: pink;
    min-height: 400px;
  }
  .Projects_project__22eSF::after {
    content: none;
  }
  .Projects_preview___jleB {
    box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.7);
    z-index: 2;
  }
  .Projects_content__VddC0 {
    min-height: 0;
  }
  .Projects_content__VddC0 p {
    position: absolute;
    bottom: 10%;
    top: 10%;
    background-color: var(--clr-background-off);
    box-shadow: -2px 2px 4px 1px rgba(5, 5, 5, 0.2);
    padding: 2rem;
    transition: all 0.5s ease-out;
    grid-gap: 2rem;
    gap: 2rem;
    width: 30ch;
    z-index: -1;
    border-radius: 10px;
  }
  li:nth-child(odd) p {
    left: 0;
    -webkit-transform: translateX(-95%);
            transform: translateX(-95%);
  }
  li:nth-child(even) p {
    right: 0;
    -webkit-transform: translateX(95%);
            transform: translateX(95%);
  }
  .Projects_content__VddC0 h2 {
    position: absolute;
    width: auto;
    border-radius: 4px;
    padding: 1rem 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 1.9vw, 2.1rem);
    transition: all 0.5s ease-out;
  }
  li:nth-child(odd) h2 {
    left: 0;
    -webkit-transform: translate(-97%, -40%);
            transform: translate(-97%, -40%);
  }
  li:nth-child(even) h2 {
    right: 0;
    -webkit-transform: translate(97%, -40%);
            transform: translate(97%, -40%);
  }
  .Projects_techContainer__Ciyem {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    width: auto;
    max-height: 6rem;
    z-index: 10;
    margin: 1rem 1rem;
    max-width: 28ch;
    padding: 0.5rem;
  }
  li:nth-child(odd) .Projects_techContainer__Ciyem {
    left: 0;
    -webkit-transform: translateX(-107%);
            transform: translateX(-107%);
  }
  li:nth-child(even) .Projects_techContainer__Ciyem {
    right: 0;
    -webkit-transform: translateX(107%);
            transform: translateX(107%);
  }
  .Projects_technologyTag__2EIqk {
    padding: 0.2rem 0.5rem;
    background-color: var(--clr-accent);
    color: var(--clr-background);
    border-radius: 15px;
    -webkit-filter: saturate(0.6);
            filter: saturate(0.6);
  }
}

.Contact_contact__1m2re {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}
.Contact_contact__1m2re h1 {
  color: var(--clr-accent);
  margin: 5rem auto 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}
.Contact_contact__1m2re h1:before {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.Contact_contact__1m2re h1:after {
  content: '';
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}

.Contact_contact__1m2re p {
  color: var(--clr-text-muted);
  font-size: clamp(1rem, 5vw, 1.3rem);
  max-width: 30ch;
  text-align: center;
  margin: 2rem;
}

.Contact_contactButton__2-Wtf {
  padding: 1rem 1.6rem;
  width: 15rem;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 3px solid var(--clr-secondary);
  border-image-slice: 1;
  color: var(--clr-secondary);
  -webkit-filter: contrast(80%);
          filter: contrast(80%);
  font-size: 1.4rem;
}
.Contact_contactButton__2-Wtf::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--clr-secondary);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.Contact_contactButton__2-Wtf:hover::before {
  opacity: 0.1;
}

footer {
  margin-top: 12rem;
  margin-bottom: 2rem;
  text-align: center;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

footer p {
  font-size: 0.9rem;
  line-height: 1.5rem;
}

footer p a {
  text-decoration: none;
  color: var(--clr-accent);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  transition: color 0.7s ease-in;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  color: var(--clr-primary-text);
}
p {
  font-size: clamp(1rem, 5vw, 1.2rem);
  color: var(--clr-primary-text);
  line-height: clamp(1.2rem, 7vw, 1.7rem);
}
button {
  all: revert;
  border: none;
  cursor: pointer;
}
ul {
  all: unset;
}
li {
  list-style-type: none;
}
.dark {
  --clr-background: hsl(0, 0%, 13%);
  --clr-background-off: #1c1c1c;
  --clr-background-gradient: linear-gradient(180deg, #212121, #1c2222, #212121);
  --clr-primary-text: hsl(357, 100%, 99%);
  --clr-accent: #00ffff;
  --clr-dark-accent: hsl(200, 90%, 40%);
  --clr-stars: rgb(241, 165, 0);
  --clr-text-muted: hsl(180, 24%, 35%);
  --clr-secondary: hsl(0deg 51% 79%);
}

.light {
  --clr-background: hsl(306deg 10% 86%);
  --clr-background-off: #ededed;
  --clr-background-gradient: linear-gradient(
    180deg,
    hsl(306deg 30% 91%),
    hsl(306deg 10% 86%),
    hsl(306deg 30% 91%)
  );
  --clr-primary-text: hsl(0, 0%, 13%);
  --clr-text-muted: hsl(324, 19%, 27%);
  --clr-accent: hsl(325, 100%, 43%);
  --clr-dark-accent: hsl(325, 100%, 26%);
  --clr-secondary: #01acac;
  --clr-stars: rgb(182, 182, 50);
}

#root {
  background-color: var(--clr-background);
  background-image: var(--clr-background-gradient);
}

