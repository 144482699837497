.sudoku {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, auto);
}
.subgrid {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(3, auto);
  height: 60px;
  width: 60px;
  animation: pop 1s linear;
  animation-fill-mode: forwards;
}
.sudokuSquare {
  height: 20px;
  width: 20px;
  background-color: rgb(243, 243, 243, 0.5);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.79);
  color: rgba(56, 56, 56, 0.529);
}

@keyframes pop {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.2;
  }
  90% {
    opacity: .6;
  }
  100% {
    opacity: 0;
  }
}
