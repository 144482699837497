.bookwise {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  filter: contrast(60%);
}

.books {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.3rem;
  width: 30%;
  margin-left: 1rem;
}
.book {
  width: 90%;
  height: 20px;
  background-color: rgb(58, 58, 58);
  border-radius: 5px;
  animation-name: fallDown;
  animation-fill-mode: forwards;
  transform-origin: left 50%;
}
.chapters {
  width: 60%;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.3rem;
}
.chapter {
  background-color: rgb(49, 49, 49);
  height: 20px;
  border-radius: 10px;
  animation-name: fallDown;
  animation-fill-mode: forwards;
  transform-origin: 30% 50%;
}
.note {
  width: 70%;
  height: 20px;
  border-radius: 10px;
  animation-name: fallDown;
  animation-fill-mode: forwards;
  transform-origin: left 50%;
  margin-top: 0.2rem;
}
.active {
  background-color: hsla(303, 67%, 34%, 0.8);
}
.pinned {
  background-color: rgba(34, 145, 34, 0.8);
}

@keyframes fallDown {
  0% {
    transform: translate(0, 0) rotate(0turn);
  }
  10% {
    transform: rotate(130deg);
  }
  20% {
    transform: rotate(60deg);
  }
  30% {
    transform: rotate(120deg);
  }
  40% {
    transform: rotate(70deg);
  }
  50% {
    transform: rotate(110deg);
  }
  80% {
    transform: translate(0, 0) rotate(90deg);
  }
  100% {
    transform: translate(0, 400px);
  }
}
