.inactive {
  animation: rotation 10s linear infinite;
}
.active {
  cursor: pointer;
  background-color: var(--clr-background);
}
.themeButton {
  position: relative;
  padding: 1rem;
  border-radius: 50%;
  aspect-ratio: 1;
  display: grid;
  transform-origin: center;
}
.themeButton > * {
  grid-column-start: 1;
  grid-row-start: 1;
}
.active:hover {
  filter: brightness(75%);
  animation: rotation 10s linear infinite;
}
.themeButton::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgb(255, 255, 255, 0.4);
  border-radius: inherit;
  transform: scale(0);
  opacity: 0;
  z-index: 100;
}
.themeButton::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--clr-stars);
  filter: brightness(150%);
  border-radius: inherit;
  transform: scale(0);
  opacity: 0;
  z-index: 100;
}
.themeButton.dark::after {
  animation: supernova 650ms ease-out;
}
.themeButton.light::before {
  animation: starborn 850ms ease-in;
}
.sun {
  fill: var(--clr-stars);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0turn) scale(1);
  transform-origin: center;
  transition: transform 1s ease-out;
  width: 100%;
}
.sun.light {
  z-index: 99;
}
.sun.dark {
  transform-origin: center;
  transform: rotate(3turn) scale(0.25);
  transition: transform 0.2s ease-in;
}
.sun.dark .circle {
  transform-origin: center;
  transform: scale(0.15);
  transition: transform 1s ease-in;
}
.blackhole.dark {
  z-index: 99;
}
.blackhole {
  transform-origin: center;
  fill: rgb(219, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease-out;
  transform: rotate(0turn) scale(1);
  width: 100%;
  height: 100%;
}
.blackhole.light {
  transform-origin: center;
  transform: rotate(3turn) scale(0.25);
  transition: transform 0.4s ease-in;
}

.stars {
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 2s ease-out 0.2s;
  transform: rotate(0turn) scale(1.3);
  fill: var(--clr-stars);
}
.stars.light {
  transform-origin: center;
  transform: rotate(1turn) scale(0.05);
  transition: transform 1s ease-in;
}
.circle {
  fill: var(--clr-background);
}

@keyframes rotation {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes supernova {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  20% {
    transform: scaleX(0) scaleY(0);
  }
  60% {
    transform: scaleX(1) scaleY(0.01);
    opacity: 1;
  }
  75% {
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes starborn {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  10% {
  }
  55% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
