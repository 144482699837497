.screen {
  width: clamp(100px, 300px, 70vw);
  height: clamp(100px, 300px, 70vw);
  transform: skew(-10deg, -10deg);
  position: relative;
  background-color: rgb(125 145 162);
  box-shadow: 12px 12px 2px 0px var(--clr-accent);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4rem;
  margin-bottom: 4rem;
}
.screen > * {
  animation: fadeIn 7s linear infinite;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1225px) {
  .screen {
    align-self: center;
    order: 1;
    margin-left: 0;
    margin-bottom: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
