main {
  display: flex;
  flex-flow: column nowrap;
}

.themeButtonContainer {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.7s ease-out;
}

.loaded.themeButtonContainer {
  top: 96%;
  left: 96%;
}
.content {
  display: flex;
  flex-flow: column;
  width: clamp(45vw, 70vw, 60rem);
  margin: auto;
  justify-content: center;
}
.content h1 {
  filter: contrast(50%);
  letter-spacing: 0.2rem;
  font-size: clamp(1rem, 5vw, 2.5rem);
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 80%;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.meContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 5rem;
}
.meContainer > * {
  width: clamp(30ch, 45vw, 50ch);
}

.tempFooter {
  height: 50px;
  padding-top: 200rem;
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 2450px) {
  .meContainer {
    justify-content: center;
  }
}
