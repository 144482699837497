@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;800;900&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  transition: color 0.7s ease-in;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  color: var(--clr-primary-text);
}
p {
  font-size: clamp(1rem, 5vw, 1.2rem);
  color: var(--clr-primary-text);
  line-height: clamp(1.2rem, 7vw, 1.7rem);
}
button {
  all: revert;
  border: none;
  cursor: pointer;
}
ul {
  all: unset;
}
li {
  list-style-type: none;
}
.dark {
  --clr-background: hsl(0, 0%, 13%);
  --clr-background-off: #1c1c1c;
  --clr-background-gradient: linear-gradient(180deg, #212121, #1c2222, #212121);
  --clr-primary-text: hsl(357, 100%, 99%);
  --clr-accent: #00ffff;
  --clr-dark-accent: hsl(200, 90%, 40%);
  --clr-stars: rgb(241, 165, 0);
  --clr-text-muted: hsl(180, 24%, 35%);
  --clr-secondary: hsl(0deg 51% 79%);
}

.light {
  --clr-background: hsl(306deg 10% 86%);
  --clr-background-off: #ededed;
  --clr-background-gradient: linear-gradient(
    180deg,
    hsl(306deg 30% 91%),
    hsl(306deg 10% 86%),
    hsl(306deg 30% 91%)
  );
  --clr-primary-text: hsl(0, 0%, 13%);
  --clr-text-muted: hsl(324, 19%, 27%);
  --clr-accent: hsl(325, 100%, 43%);
  --clr-dark-accent: hsl(325, 100%, 26%);
  --clr-secondary: #01acac;
  --clr-stars: rgb(182, 182, 50);
}

#root {
  background-color: var(--clr-background);
  background-image: var(--clr-background-gradient);
}
