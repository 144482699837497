.chessboard {
  width: 200px;
  height: 200px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(8, auto);
  overflow: hidden;
}
.square {
  width: 20px;
  height: 20px;
  background-color: rgba(243, 243, 243, 0.7);
  animation: moveLeft 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.chessboard div:nth-child(-2n + 8),
.chessboard div:nth-child(8) ~ div:nth-child(-2n + 15),
.chessboard div:nth-child(16) ~ div:nth-child(-2n + 24),
.chessboard div:nth-child(24) ~ div:nth-child(-2n + 31),
.chessboard div:nth-child(32) ~ div:nth-child(-2n + 40),
.chessboard div:nth-child(40) ~ div:nth-child(-2n + 47),
.chessboard div:nth-child(48) ~ div:nth-child(-2n + 56),
.chessboard div:nth-child(56) ~ div:nth-child(-2n + 63) {
  background-color: hsl(29, 34%, 10%, 0.7);
}

@keyframes moveLeft {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-400px, 0px);
  }
}
