.techContainer {
  margin-left: auto;
}
.techContainer h1 {
  text-align: right;
  margin-left: auto;
  color: var(--clr-accent);
}
.techContainer h1:after {
  content: '';
  height: 1px;
  min-width: 10px;
  background-color: currentColor;
}
.techContainer h1::before {
  content: '';
  margin-left: 1rem;
  height: 1px;
  flex-grow: 2;
  min-width: 30px;
  background-color: currentColor;
}
.zones {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 2rem;
}
.zone {
  flex-basis: 44%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  align-items: center;
  margin: auto;
}
.zone > div {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.zone > div::before {
  content: '';
  height: 100px;
  width: 200px;
  position: relative;
  border-radius: 29px;
}
.zone div img {
  max-height: 70%;
  position: absolute;
}
.zone h2 {
  color: var(--clr-secondary);
  filter: contrast(40%);
  text-align: center;
  margin: 0.6rem 0rem;
  width: 13ch;
}
.zone p {
  color: var(--clr-text-muted);
  font-size: 1rem;
  max-width: 20ch;
  text-align: center;
}
@media screen and (max-width: 2450px) {
  .techContainer {
    margin-left: 0;
  }
  .zones {
    justify-content: center;
  }
  .zone {
    justify-content: center;
  }
}
